import { ILanguage } from '@/plugins/phoenix/types';

const lang: ILanguage = {
  code: 'ru',
  name: 'Русский',
  short: 'Ру',
  translates: {
    system: {
      logon: 'Вход',
      logout: 'Выход',
      register: 'Регистрация',
      firstname: 'Имя',
      lastname: 'Фамилия',
      cor: 'Выбрать страну резиденства',
      mailingAccept: 'Хочу получать особые предложения',
      termsAcceptHead: 'Пожалуйста ознакомьтесь с условиями пользования прежде чем продолжить регистрацию в <b>%1</b> и покупку криптовалюты. Вам необходимо согласиться с ними чтобы продолжить.',
      termsAccept: 'Я согласен с <a href="%1" target="blank"><b>условиями пользования</b></a>',
      emailCollect: 'Перед регистрацией в <b>%1</b> и покупкой криптовалюты Вам необходимо ввести свой адрес электронной почты, на который мы вышлем проверочный код.',
      emailCheck: 'Проверьте Вашу почту и введите проверочный код, который мы прислали.',
      loginNeeded: 'Вы уже были зарегистрированы в <b>%1</b> с этой электронной почтой. Введите пароль, для подтверждения доступа. Если вы не помните пароль, перейдите на страницу входа в <a href="%2" target="blank">%1</a> и нажмите "Забыл пароль".',
      alreadyCommited: 'Ваша транзакция уже завершена. Пожалуйста закройте эту страницу.',
      login: 'Вход',
      loginName: 'Имя пользователя',
      password: 'Пароль',
      email: 'Э-почта',
      COR: 'Страна резиденства',
      code: 'Код',
      resendCode: 'Получить код еще раз',
      loading: 'Загрузка',
      siteCopyrights: 'Все права защищены',
      siteOperated: 'Этот вебсайт управляется',
      siteLegalDocs: 'Правовая информация и конфиденциальность',
      close: 'Закрыть',
      back: 'Назад',
      continue: 'Продолжить',
      send: 'Отправить',
      buyAndSend: 'Купить и отправить',
      makeDeposit: 'Я сделал перевод',
      canClose: 'Вы можете закрыть это окно',
      sendAgree: 'Я хочу купить и отправить криптовалюту на ранее заданный кошелек:',
      sendWarningHead: 'Остерегайся мошенников и киберпреступников!',
      sendWarningText: 'Будьте всегда предельно осторожны когда отправляете криптовалюту. Однажды отправленную транзакцию невозможно отменить или вернуть назад.',
      depositWarningHead: 'Будьте внимательны и осторожны!',
      depositWarningTextFlex: 'Вам необходимо отправить монеты на указанный ниже адрес <b>%1</b>. Суммы меньше чем <b>%2 %3</b> не будут зачислены! Однажды отправленную транзакцию невозможно отменить или вернуть назад.',
      depositWarningTextStrict: 'Вам необходимо отправить монеты на указанный ниже адрес <b>%1</b>. Сумма не равная <b>%2 %3</b> не будет зачислена! Однажды отправленную транзакцию невозможно отменить или вернуть назад.',
      selectCOR: 'Пожалуйста выберите Вашу страну резиденства из списка ниже',
      cancel: 'Отказаться',
      wallet: 'Номер кошелька получателя',
      walletSender: 'Номер кошелька отправителя',
      forgetPassword: 'Забыл пароль',
      forgetPasswordText: 'Если Вы не можете вспомнить пароль, то введите пожалуйста в окошко ниже адрес э-почты, с который вы регистрировались на платформе и мы вышлем Вам на него инструкции по восстановлению пароля.',
      recoveryEmailSent: 'Письмо с инструкциями по сбросу пароля, было отправлено',
      passwordHint: 'Пароль должен быть не менее 8ми знаков и содержать как минимум одну заглавную букву, одну маленькую буква, одну цифру и один специальный символ',
      paymentDescriptor: 'Цель платежа',
      paymentAmount: 'Сумма платежа',
      chargeAmount: 'Будет списано',
      phone: 'Телефон',
      address: {
        city: 'Город',
        state: 'Штат',
        country: 'Страна',
        zip: 'Индекс',
        street: 'Улица',
      },
      birthday: 'Дата рождения',
      document: {
        type: 'Тип документа',
        types: {
          ID: 'ИД карта',
          PS: 'Пасспорт',
        },
        number: 'Номер документа',
        issued: 'Документ выдан',
        expired: 'Документ годен до',
      },
      bank: {
        account: 'Номер счета',
        bic: 'Код банка (BIC)',
        branch: 'Филиал банка',
        owner: 'Имя владельца счета',
      },

      errors: {
        'HTTP': 'Сервер %1 сейчас не доступен. Обратитесь к вашему администратору или попробуйте еще раз.',
        'SYS': 'В %1 была допущена критическая ошибка. Текущая операция будет прервана принудительно.',
        'USR008': 'Проверочный код неверный.',
        'LGN': 'Неверное имя пользователя или пароль.',
        'CARD_NUMBER': 'Неправильный номер карты.',
        'CARD_EXPIRED': 'Неправильная дата срока годности карты.',
        'CARD_CVC': 'Неправильный код CVC/CVV.',
        'CARD_NAME': 'Не указано умя владельца карты.',
        'CARD_EMPTY': 'Пожалуйста заполните данные карты.',
        'CHK001': 'Некорректный шаг запроса',
        'CHK002': 'Запрос не найден',
        'CHK003': 'Неизвестный провайдер',
        'CHK004': 'Запрос завершился неудачей',
        'CHK005': 'Запрос отменен',
        'ACC002': 'Недостаточно средств на карте',
        'CHL001': 'Временные проблемы при обработки транзакции, пожалуйста попробуйте позже',
        'CHL002': 'Техническая ошибка, пожалуйста обратитесь в поддержку',
        'CHL003': 'Ошибка обработки транзакции',
        'CHL004': 'Ошибка обработки транзакции',
        'CHL005': 'Ошибка обработки транзакции',
        'CHL006': 'Невозможно обработать карту, пожалуйста попробуйте другую',
        'CHL007': 'Временные проблемы при обработки транзакции, пожалуйста попробуйте позже',
        'CHL008': 'Невозможно обработать карту, пожалуйста попробуйте другую',
        'CHL009': 'Невозможно обработать карту, пожалуйста попробуйте другую',
        'FIN012': 'Ошибка обработки транзакции',
        'PRC004': 'Время транзакции истекло',
        'PRC005': 'Ошибка обработки транзакции',
        'PRC014': 'Transaction has been canceled',
        'PRC019': 'Невозможно обработать карту, пожалуйста попробуйте другую',
        'PRC020': 'Некорректные данные карты (номер, срок или имя)',
        'PRCRT2': 'Ошибка обработки транзакции',
        'PRCRT8': 'Невозможно обработать карту, пожалуйста попробуйте другую',
        'SYS007': 'Неудачная проверка безопасности карты (CVV/CVC или 3DS)',
        'SYS013': 'Время транзакции превышено',
      },
    },
    checkout: {
      initializing: 'Подготовка...',
      steps: {
        TERMS: {
          processing: 'Фиксируется согласие... ',
        },
        COR: {
          processing: 'Определение страны... ',
        },
        BILL: {
          processing: 'Идет проверка... ',
        },
        LOGON: {
          processing: 'Выполняется вход... ',
        },
        EMAIL: {
          processing: 'Идет проверка почты... ',
        },
        NEWCS: {
          processing: 'Создается Ваш профиль... ',
        },
        IDENT: {
          processing: 'Создается идентификация... ',
        },
        PAYMET: {
          processing: 'Подготовка карты... ',
        },
        FINOPER: {
          processing: 'Обрабатывается платеж, пожалуйста подождите и не закрывайте это окно...',
        },
        WALLET: {
          processing: 'Поиск кошелька... ',
        },
        WAIT: {
          processing: 'Обработка... ',
          deposit: 'Ожидание пополнения... ',
        },
      },
    },
  },
}

export default lang;
