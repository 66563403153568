import { ANY_ACCESS } from './const';

export type AccessMap = Record<string, boolean>;
export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export class Access {
  protected value: string

  constructor(value: string) {
    this.value = value
  }

  public toString(): string {
    return String(this.value);
  }

  public is(value: Access | string): boolean {
    return this.value === value.toString();
  }
}

export class BaseAccess extends Access {
  public static ANY = new Access(ANY_ACCESS);
}

export interface IAccessChecker {
  accessRoute(path: string): boolean
}

export interface ITranslates extends Record<string, ITranslates | string> { }

export interface ILanguage {
  code: string;
  name: string;
  short: string;
  translates: ITranslates;
}

export interface IStopwatch {
  from: number;
  to: number;
  stop(): number;
}

export interface IView {
  name: string,
  component: any
}

export interface ILink extends IView {
  path: string,
  access: Access,
  props?: boolean
}

export interface MenuItem {
  id: number
  path: string
  caption: string
  icon: string | null
  baldge: string | null
  color: string | null
  childs: Array<MenuItem>
  callback?: () => void
}
