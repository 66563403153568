import { ILanguage } from '@/plugins/phoenix/types';

const lang: ILanguage = {
  code: 'en',
  name: 'English',
  short: 'En',
  translates: {
    system: {
      logon: 'Sign in',
      logout: 'Sign out',
      register: 'Register',
      firstname: 'First name',
      lastname: 'Last name',
      cor: 'Select country of your residence',
      mailingAccept: 'I would like to receive special offers',
      termsAcceptHead: 'Please read the Legal &amp; Privacy before proceeding with the registration in <b>%1</b> and purchase of cryptocurrency. You need to agree with them to continue.',
      termsAccept: 'I agree with <a href="%1" target="blank"><b>Legal &amp; Privacy</b></a>',
      emailCollect: 'Before proceeding with the registration in <b>%1</b> and purchase of cryptocurrency you need enter your email address to which we will send a verification code.',
      emailCheck: 'Check your email and enter verification code.',
      loginNeeded: 'You have already been registered in <b>%1</b> with this email. Enter a password to confirm access. If you don\'t remember your password, go to the login page at <a href="%2" target="blank">%1</a> and click "Forgot Password".',
      alreadyCommited: 'Your transaction already done. Please close this page.',
      login: 'Log In',
      loginName: 'User name',
      password: 'Password',
      email: 'E-mail',
      COR: 'Country of residense',
      code: 'Code',
      resendCode: 'Resend code',
      loading: 'Loading',
      siteCopyrights: 'All rights reserved',
      siteOperated: 'The website is operated by',
      siteLegalDocs: 'Legal &amp; Privacy',
      close: 'Close',
      back: 'Back',
      continue: 'Continue',
      send: 'Send',
      buyAndSend: 'Purchase & send',
      makeDeposit: 'I transferred money',
      canClose: 'You can close this window',
      sendAgree: 'I want to send purchased cryptocurrency to the prefilled wallet:',
      sendWarningHead: 'Beware of scammers and cybercriminals!',
      sendWarningText: 'Always be careful when withdrawing cryptocurrencies. Once a withdrawal has been sent, it is impossible to cancel or reverse the transaction.',
      depositWarningHead: 'Be attentive and careful!',
      depositWarningTextFlex: 'You need to send coins to the <b>%1</b> address below. Amounts less than <b>%2 %3</b> will not be credited! Once a coins has been sent, it is impossible to cancel or reverse the transaction.',
      depositWarningTextStrict: 'You need to send coins to the address below <b>%1</b>. Amount not equal to <b>%2 %3</b> will not be credited! Once a coins has been sent, it is impossible to cancel or reverse the transaction.',
      selectCOR: 'Please select your country of residence in list below',
      cancel: 'Cancel',
      wallet: 'Recipient\'s wallet number',
      walletSender: 'Your wallet number',
      forgetPassword: 'Forgot password',
      forgetPasswordText: 'If you cannot remember your password, please enter your platform profile e-mail address in the box below and we will send you instructions how recovery your password.',
      recoveryEmailSent: 'Mail with recovery password instructions has been sent',
      passwordHint: 'Password must be with 8 or more characters with mixed case letters numbers and punctuation marks',
      paymentDescriptor: 'Payment descriptor',
      paymentAmount: 'Payment amount',
      chargeAmount: 'Will be charged',
      phone: 'Phone',
      address: {
        city: 'Town',
        state: 'State',
        country: 'Country',
        zip: 'ZIP',
        street: 'Street',
      },
      birthday: 'Birthday date',
      document: {
        type: 'Document type',
        types: {
          ID: 'ID card',
          PS: 'Passport',
        },
        number: 'Document number',
        issued: 'Document issued',
        expired: 'Document expired',
      },
      bank: {
        account: 'Account number',
        bic: 'Bank code (BIC)',
        branch: 'Bank branch',
        owner: 'Account owner name',
      },

      errors: {
        'HTTP': '%1 server is not available. Contact your administrator or try again.',
        'SYS': 'Your %1 has encountered a critical error. Current operation will be terminated.',
        'USR008': 'Verification code is invalid.',
        'LGN': 'Incorrect user name or password.',
        'CARD_NUMBER': 'Incorrect or empty card number.',
        'CARD_EXPIRED': 'Incorrect or empty card expiration date.',
        'CARD_CVC': 'Incorrect or empty CVC/CVV.',
        'CARD_NAME': 'Empty card owner name.',
        'CARD_EMPTY': 'Please fill card data.',
        'CHK001': 'Invalid request step',
        'CHK002': 'Request not found',
        'CHK003': 'Unknown provider',
        'CHK004': 'Request failed',
        'CHK005': 'Request canceled',
        'ACC002': 'Insufficient funds on the card',
        'CHL001': 'Temporary transaction processing error, please try again later',
        'CHL002': 'Technical error, please contact support',
        'CHL003': 'Transaction processing error',
        'CHL004': 'Transaction processing error',
        'CHL005': 'Transaction processing error',
        'CHL006': 'Unprocessable card, please try another one',
        'CHL007': 'Temporary transaction processing error, please try again later',
        'CHL008': 'Unprocessable card, please try another one',
        'CHL009': 'Unprocessable card, please try another one',
        'FIN012': 'Transaction processing error',
        'PRC004': 'Transaction expired',
        'PRC005': 'Transaction processing error',
        'PRC014': 'Transaction has been canceled',
        'PRC019': 'Unprocessable card, please try another one',
        'PRC020': 'Incorrect card details (number, expiration date or name)',
        'PRCRT2': 'Transaction processing error',
        'PRCRT8': 'Unprocessable card, please try another one',
        'SYS007': 'Card security check failed (CVV/CVC or 3DS)',
        'SYS013': 'Transaction timeout',
      },
    },
    checkout: {
      initializing: 'Initializing...',
      steps: {
        TERMS: {
          processing: 'Storing agree ... ',
        },
        COR: {
          processing: 'Country detection... ',
        },
        BILL: {
          processing: 'Checking billing data... ',
        },
        LOGON: {
          processing: 'Signing in... ',
        },
        EMAIL: {
          processing: 'Checking email... ',
        },
        NEWCS: {
          processing: 'Creating your profile... ',
        },
        IDENT: {
          processing: 'Creating identification... ',
        },
        PAYMET: {
          processing: 'Preparing payment method... ',
        },
        FINOPER: {
          processing: 'Processing payment, please wait and do not close this window... ',
        },
        WALLET: {
          processing: 'Searching wallet... ',
        },
        WAIT: {
          processing: 'Processing... ',
          deposit: 'Waiting for deposit ... ',
        },
      },
    },
  },
}

export default lang;
