import axios from 'axios';
import VueRouter, { NavigationGuardNext, Route } from 'vue-router';
import Vue, { PluginObject } from 'vue';
import { DateFormat, DateFormatOptions, NumberFormat, NumberFormatOptions } from './formats';
import { GlossaryItemCode, GlossaryOrder, IGlossary, IGlossaryItem } from './glossaries';
import { AccessMap, BaseAccess, HttpMethod, IAccessChecker, ILanguage, ILink, IStopwatch, ITranslates, IView, MenuItem } from './types';

let $$downloadLink: any = null;

export interface IPhoenixModule {
  name(): string;
  init(): Promise<any>;
  routes(): Array<ILink>;
  userMenu(): Array<MenuItem>;
  appMenu(): Array<MenuItem>;
  toolbar(): Array<MenuItem>;
  reports(): Array<IView>;
}

export interface PhoenixOptions {
  router?: VueRouter
  accesses?: AccessMap
  modules?: Array<IPhoenixModule>
  glossaries?: Array<IGlossary<any, any>>

  environment?: {
    name: string
    debug: boolean
  }

  http?: {
    baseUrl?: string | null
    csrfToken?: string | null
  }

  formats?: {
    datetime?: DateFormat
    decimal?: NumberFormat
  }

  localization?: {
    currentLanguage?: string
    languages?: Array<ILanguage>
    labels?: {
      duration?: {
        sec?: string
        min?: string
        hour?: string
        day?: string
        remain?: string
        before?: string
      }
    }
  }

  links?: {
    home?: string
  }
}

export interface IPhoenixApp extends PluginObject<PhoenixOptions>, IAccessChecker {
  // Logging
  log(...args: any[]): void
  info(...args: any[]): void
  warn(...args: any): void
  error(...args: any): void

  // Utility
  wait(ms: number): Promise<any>
  delay(key: string, ms: number, task: () => void): void
  stopwatch(): IStopwatch
  clone(obj: any): any
  merge(src: any, dst: any): void
  setup(options?: PhoenixOptions): void
  init(): Promise<any>
  environment(): string;
  isDebug(): boolean;
  copyInput(e: any): void;
  copyText(e: any): void;

  // Security
  access(access: BaseAccess | Array<BaseAccess>): boolean
  route(to: Route, from: Route, next: NavigationGuardNext<any>): void

  // Links
  link(path: string): ILink | null
  links(): Array<ILink>
  appMenu(): MenuItem
  userMenu(): MenuItem
  toolbar(): MenuItem

  // Localization
  i18n(key: any, ...args: any[]): string
  applyLocalizations(list: Record<string, string>): void
  currentLanguage(): ILanguage
  languages(): Array<ILanguage>

  // Glossaries
  item<T extends GlossaryItemCode>(glossary: string, code: GlossaryItemCode, def?: IGlossaryItem<T>): IGlossaryItem<T>
  itemLabel(glossary: string, code: GlossaryItemCode, def?: string): string
  glossary(glossary: string): IGlossary<any, any>
  list<T extends GlossaryItemCode, I extends IGlossaryItem<T>>(name: string, order: GlossaryOrder, anyCode?: T): Array<I>

  // HTTP
  baseUrl(): string;
  http(url: string, method: HttpMethod, binary: boolean, params?: any, body?: any, contentType?: string, progress?: any): Promise<any>
  get(url: string, params?: any): any
  post(url: string, body: any, params?: any, contentType?: string): any
  put(url: string, body: any, params?: any, contentType?: string): any
  patch(url: string, body: any, params?: any, contentType?: string): any
  delete(url: string, params?: any): any
  request(url: string, method: HttpMethod, params: any, body?: any, contentType?: string): any
  binary(url: string, method: HttpMethod, params: any, body?: any, contentType?: string, progress?: any): any
  download(data: any, fileName: string, mime: string): void
  downloadByLink(url: string): void

  // Formatting
  duration(from: any, to: any): string
  format(value: any, options?: DateFormatOptions | NumberFormatOptions): string
  dateFormat(): DateFormat
  decimalFormat(): NumberFormat
}

export interface ICompiledLanguage extends ILanguage {
  messages: Record<string, string>
}

export class AppError extends Error {
  code: string = ''

  constructor(public errorCode: string, message: string, public details?: string, public hidden?: boolean, public timeout?: number) {
    super(message)
    this.code = errorCode
    Object.setPrototypeOf(this, AppError.prototype)
  }
}

export class AppMenu implements MenuItem {
  id: number = 0
  path: string = '/'
  caption: string = ''
  icon: string | null = null
  baldge: string | null = null
  color: string | null = null
  childs: Array<MenuItem> = []
  callback?: () => void

  constructor(
    id: number,
    path: string,
    caption: string,
    icon: string | null = null,
    baldge: string | null = null,
    color: string | null = null,
    childs: Array<MenuItem> = [],
    callback?: () => void,
  ) {
    this.id = id;
    this.path = path;
    this.caption = caption;
    this.icon = icon;
    this.baldge = baldge;
    this.color = color;
    this.childs = childs;
    this.callback = callback;
  }
}

export class RootMenu implements MenuItem {
  id: number = 0
  path: string = '/'
  caption: string = ''
  icon: string | null = null
  baldge: string | null = null
  color: string | null = null
  childs: Array<MenuItem> = []
  callback?: () => void
}

export class PhoenixApp implements IPhoenixApp {
  $modules: Array<IPhoenixModule> = []
  $routes: Record<string, ILink> = {}
  $appMenu: RootMenu = new RootMenu()
  $userMenu: RootMenu = new RootMenu()
  $toolbar: RootMenu = new RootMenu()
  $router: VueRouter | null = null
  $tasks: Record<string, number> = {}
  $glossaries: Record<string, IGlossary<any, any>> = {}

  $access: AccessMap = { ANY_ACCESS: true }

  $languages: Array<ILanguage> = []
  $language: ICompiledLanguage = {
    code: 'en',
    name: 'English',
    short: 'En',
    translates: {},
    messages: {},
  }

  _isInitialized = false

  _environment = ''
  _isDebug = true
  _baseUrl = ''
  _csrfToken = ''

  _dateFormat = new DateFormat('-', 'DD-MM-YYYY', 'HH:MM')
  _decimalFormat = new NumberFormat('.', 3, ' ')

  _homeLink = '/'

  labels = {
    duration: {
      sec: 'sec',
      min: 'min',
      hour: 'hour',
      day: 'day',
      remain: 'remain',
      before: 'before',
    },
  }

  install(Vue: any, options?: PhoenixOptions) {
    Vue.prototype.$ph = this
    if (options) {
      this.setup(options)
    }

    $$downloadLink = document.createElement('a');
    $$downloadLink.id = '_______phoenix_download_______';
    $$downloadLink.style.cssText = 'display:none;';
    document.body.appendChild($$downloadLink);
  }

  currentLanguage(): ILanguage {
    return this.$language
  }

  languages(): Array<ILanguage> {
    return this.$languages
  }

  dateFormat(): DateFormat {
    return this._dateFormat
  }

  decimalFormat(): NumberFormat {
    return this._decimalFormat
  }

  baseUrl(): string {
    return this._baseUrl
  }

  environment(): string {
    return this._environment
  }

  isDebug(): boolean {
    return this._isDebug
  }

  load() {
    this.$routes = {}
    this.$modules.forEach(module => this.loadModule(module))
  }

  loadModule(module: IPhoenixModule) {
    for (const menu of module.appMenu()) {
      this.$appMenu.childs.push(menu)
    }

    for (const menu of module.userMenu()) {
      this.$userMenu.childs.push(menu)
    }

    for (const menu of module.toolbar()) {
      this.$toolbar.childs.push(menu)
    }

    const moduleRoutes = module.routes()
    for (const route of moduleRoutes) {
      this.$routes[route.path] = route
    }

    this.info('Module installed: ' + module.name())
  }

  registerAllRoutes() {
    if (this.$router) {
      for (let key in this.$routes) {
        this.$router.addRoute(this.$routes[key]);
      }
    }
  }

  async init() {
    for (let i = 0; i < this.$modules.length; i++) {
      this.log('Initializing module ' + this.$modules[i].name() + '...')
      await this.$modules[i].init();
    }

    this.registerAllRoutes();

    this._isInitialized = true;
  }

  copyInput(e: any) {
    let el: any = e.target
    let input: any = el.querySelector('input')

    while (!input && el) {
      el = el.parentElement
      input = el.querySelector('input')
    }

    if (input) {
      input.select()
      document.execCommand('copy');
      input.setSelectionRange(0, 0);
    }
  }

  copyText(e: any) {
    navigator.clipboard.writeText(e || '')
  }

  log(...args: any[]): void {
    if (this._isDebug) {
      /* eslint-disable no-console */
      if (this._environment !== '') {
        args.unshift('[' + this._environment + ']')
      }
      console.log(...args)
      /* eslint-enable no-console */
    }
  }

  info(...args: any[]): void {
    /* eslint-disable no-console */
    if (this._environment !== '') {
      args.unshift('[' + this._environment + ']')
    }
    console.info(...args)
    /* eslint-enable no-console */
  }

  warn(...args: any): void {
    /* eslint-disable no-console */
    if (this._environment !== '') {
      args.unshift('[' + this._environment + ']')
    }
    console.warn(...args)
    /* eslint-enable no-console */
  }

  error(...args: any): void {
    /* eslint-disable no-console */
    if (this._environment !== '') {
      args.unshift('[' + this._environment + ']')
    }
    console.error(...args)
    /* eslint-enable no-console */
  }

  wait(ms: number): Promise<any> {
    return new Promise((resolve) => {
      setTimeout(() => { resolve('') }, ms)
    })
  }

  delay(key: string, ms: number, task: () => void): void {
    let id = this.$tasks[key];

    if (id) {
      clearTimeout(id);
    }

    id = setTimeout(task, ms);
    this.$tasks[key] = id;
  }

  stopwatch(): IStopwatch {
    const init: number = performance.now()
    return {
      from: init,
      to: init,
      stop: function () {
        this.to = performance.now()
        return Math.round(this.to - this.from)
      },
    }
  }

  clone(obj: any) {
    return clone(obj)
  }

  merge(src: any, dst: any): void {
    if (src instanceof Object && dst instanceof Object) {
      for (let attr in src) {
        if (src.hasOwnProperty(attr) && dst.hasOwnProperty(attr)) {
          dst[attr] = src[attr];
        }
      }
    }
  }

  setup(options?: PhoenixOptions): void {
    if (options) {
      if (options.router) {
        this.$router = options.router;
      }

      if (options.modules) {
        this.$modules = options.modules;
        this.load();
      }

      if (options.environment) {
        this._environment = options.environment.name;
        this._isDebug = options.environment.debug;
      }

      this.$access = options.accesses || this.$access;

      if (options.http) {
        this._baseUrl = options.http.baseUrl === undefined ? this._baseUrl : (options.http.baseUrl || '');
        this._csrfToken = options.http.csrfToken || this._csrfToken;
      }

      if (options.formats) {
        this._dateFormat = options.formats.datetime || this._dateFormat;
        this._decimalFormat = options.formats.decimal || this._decimalFormat;
      }

      if (options.links) {
        this._homeLink = options.links.home || this._homeLink;
      }

      if (options.glossaries) {
        options.glossaries.forEach(gl => Vue.set(this.$glossaries, gl.name, gl))
      }

      if (options.localization) {
        const curLang = options.localization.currentLanguage || this.$language.code
        this.$languages = options.localization.languages || this.$languages

        const lang = this.$languages.find(l => l.code === curLang)
        if (lang) {
          const store: Record<string, string> = {}
          this.loadTranslates('', lang.translates, store)
          this.$language = {
            code: lang.code,
            name: lang.name,
            short: lang.short,
            translates: lang.translates,
            messages: store,
          }
        }

        if (options.localization.labels && options.localization.labels.duration) {
          this.labels.duration.sec = options.localization.labels.duration.sec || this.labels.duration.sec;
          this.labels.duration.min = options.localization.labels.duration.min || this.labels.duration.min;
          this.labels.duration.hour = options.localization.labels.duration.hour || this.labels.duration.hour;
          this.labels.duration.day = options.localization.labels.duration.day || this.labels.duration.day;
          this.labels.duration.remain = options.localization.labels.duration.remain || this.labels.duration.remain;
          this.labels.duration.before = options.localization.labels.duration.before || this.labels.duration.before;
        }
      }
    }
  }

  loadTranslates(root: string, msg: ITranslates, store: Record<string, string>): void {
    for (let attr in msg) {
      if (msg.hasOwnProperty(attr)) {
        let value = msg[attr];
        if (value instanceof Object) {
          this.loadTranslates(root === '' ? attr : root + '.' + attr, value, store)
        } else {
          Vue.set(store, root === '' ? attr : root + '.' + attr, value)
        }
      }
    }
  }

  access(access: BaseAccess | BaseAccess[]): boolean {
    let list = access instanceof Array ? access : [access || BaseAccess.ANY]
    return list.some(a => this.$access[a.toString()])
  }

  accessRoute(path: string): boolean {
    let link = this.link(path);
    return link !== null && this.access(link.access);
  }

  async doRouting(to: Route, from: Route, next: NavigationGuardNext<any>, fullReload: boolean) {
    while (!this._isInitialized) {
      await this.wait(100);
    }

    if (this.$router) {
      let route = to;

      if (route.matched.length === 0) {
        route = this.$router.resolve(route.fullPath).route;
      }

      let link: ILink | null = null;

      for (let i = 0; i < route.matched.length; i++) {
        link = this.link(route.matched[i].path);
      }

      if (link === null) {
        if (route.path === this._homeLink) {
          this.error('Fatal route error, home path not found: ' + route.path)
        } else {
          this.warn('Invalid route: "' + to.path + '", redirecting to home page...')
          next(this._homeLink)
        }
      } else {
        const hasAccess: boolean = this.access(link.access)

        if (!hasAccess) {
          if (route.path === this._homeLink) {
            this.error('Fatal route error, home path not accessible: ' + link.access.toString())
          } else {
            this.warn('Access denied: "' + to.path + '", redirecting to home page...')
            next('/')
          }
        } else if (fullReload) {
          next(route.fullPath)
        } else {
          next()
        }
      }
    }
  }

  route(to: Route, from: Route, next: NavigationGuardNext<any>): void {
    const fullReload = !this._isInitialized;
    this.doRouting(to, from, next, fullReload)
  }

  link(path: string): ILink | null {
    path = !path || path === '' ? '/' : path
    return this.$routes[path] || null
  }

  links(): ILink[] {
    return Object.keys(this.$routes).map(name => this.$routes[name])
  }

  appMenu(): MenuItem {
    return this.$appMenu
  }

  userMenu(): MenuItem {
    return this.$userMenu
  }

  toolbar(): MenuItem {
    return this.$toolbar
  }

  i18n(key: any, ...args: any[]): string {
    let text
    if (typeof key === 'object') {
      text = key[this.$language.code]
      if (text === undefined || text === null || text === '') {
        text = ''
      }
    } else {
      text = this.$language.messages[key]
      text = text || key
    }

    if (args && args.length > 0) {
      for (let i = 0; i < args.length; i++) {
        let re = new RegExp('%' + (i + 1), 'g');
        text = text.replace(re, args[i]);
      }
    }

    return text;
  }

  applyLocalizations(list: Record<string, string>): void {
    this.$language.messages = Object.assign({}, this.$language.messages, list)
  }

  item<T extends GlossaryItemCode>(glossary: string, code: GlossaryItemCode, def?: IGlossaryItem<T>): IGlossaryItem<T> {
    let gl: IGlossary<any, any> = this.glossary(glossary);
    return gl.map[code] || (def || { code, label: 'system.states.?', color: 'disabled', icon: 'fa-question-circle' });
  }

  itemLabel(glossary: string, code: GlossaryItemCode, def?: string): string {
    const item: IGlossaryItem<any> = this.item(glossary, code);
    return item && item.label !== 'system.states.?' ? this.i18n(item.label) : (def || '' + code);
  }

  glossary(glossary: string): IGlossary<any, any> {
    let result = this.$glossaries[glossary];
    if (!result) {
      result = {
        name: glossary,
        map: {},
        items: [],
        reload: function () { },
      };
    }
    return result;
  }

  list<T extends GlossaryItemCode, I extends IGlossaryItem<T>>(name: string, order: GlossaryOrder, anyCode?: T): I[] {
    const list: Array<I> = this.glossary(name).items.concat().sort((a, b) => {
      if (order === 'byKey') {
        return a.code ? ('' + a.code).localeCompare('' + b.code) : (b.code ? 1 : 0)
      } else if (order === 'byValue') {
        return a.label ? this.i18n(a.label).localeCompare(this.i18n(b.label)) : (b.label ? 1 : 0)
      } else {
        return 0
      }
    });

    if (anyCode && list) {
      const anyItem: I = <I>{
        code: anyCode,
        label: 'system.any',
        color: 'disabled',
        icon: 'fa-question-circle',
        textColor: null,
      }
      list.unshift(anyItem);
    }

    return list;
  }

  async http(resource: string, method: HttpMethod, binary: boolean, params?: any, body?: any, contentType?: string, progress?: any): Promise<any> {
    const url = this._baseUrl ? this._baseUrl + resource : resource;

    this.log('HTTP>', method + ' ' + url, 'Params:', params, 'Body:', progress !== undefined ? '[binary]' : body)

    if (!contentType) {
      if (body instanceof Object) {
        contentType = 'application/json'
      } else {
        contentType = 'text/html'
      }
    }

    try {
      let response = await axios({
        method: method,
        url: url,
        params: params,
        data: body,
        headers: {
          'Content-Type': contentType,
          'X-SECURITY-TOKEN': this._csrfToken || '',
          'X-Referer': document.referrer,
        },
        onUploadProgress: progress,
      })

      this.log('HTTP<', method + ' ' + url, 'Response:', response)
      contentType = response.headers['content-type']

      if (contentType !== 'application/json' || response.data.errorCode === undefined || response.data.errorCode === 'OK') {
        if (contentType === 'application/json' || !contentType) {
          return response.data.body
        } else {
          if (response.headers['content-disposition']) {
            let fileName = response.headers['content-disposition'].split('filename=')[1];
            this.download(response.data, fileName, contentType || 'application/octet-stream')
          } else {
            return response.data
          }
        }
      } else {
        throw new AppError(response.data.errorCode, response.data.errorText, response.data.errorDetails)
      }
    } catch (error) {
      const err: any = error
      if (err.errorCode === undefined && err.response) {
        if (err.response.data && err.response.data.errorCode) {
          throw new AppError(err.response.data.errorCode, err.response.data.errorText, err.response.data.errorDetails)
        } else {
          throw new AppError('HTTP' + err.response.status, err.message + ' (' + method + ' ' + url + ')', err.response)
        }
      } else {
        throw err
      }
    }
  }

  download(data: any, fileName: string, mime: string): void {
    const navigator: any = window.navigator
    if (navigator && navigator.msSaveOrOpenBlob) { // IE variant
      navigator.msSaveOrOpenBlob(new Blob([data], { type: mime }), fileName);
    } else if ($$downloadLink) {
      $$downloadLink.setAttribute('href', 'data:' + mime + ',' + encodeURIComponent(data));
      $$downloadLink.setAttribute('download', fileName);

      if (document.createEvent) {
        var event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        $$downloadLink.dispatchEvent(event);
      } else {
        $$downloadLink.click();
      }
    }
  }

  downloadByLink(url: string): void {
    const link = document.createElement('a');
    link.id = '__phdw_' + Date.now() + '_' + Math.floor(Math.random() * 1000000);
    link.style.cssText = 'display:none;';
    link.setAttribute('href', url);
    document.body.appendChild(link);
    link.click();
    setTimeout(() => { document.body.removeChild(link) }, 5000)
  }

  get(url: string, params?: any): any {
    return this.http(url, 'GET', false, params)
  }

  post(url: string, body: any, params?: any, contentType?: string): any {
    return this.http(url, 'POST', false, params, body, contentType)
  }

  put(url: string, body: any, params?: any, contentType?: string): any {
    return this.http(url, 'PUT', false, params, body, contentType)
  }

  patch(url: string, body: any, params?: any, contentType?: string): any {
    return this.http(url, 'PATCH', false, params, body, contentType)
  }

  delete(url: string, params?: any): any {
    return this.http(url, 'DELETE', false, params)
  }

  request(url: string, method: HttpMethod, params: any, body?: any, contentType?: string): any {
    return this.http(url, method, false, params, body, contentType)
  }

  binary(url: string, method: HttpMethod, params: any, body?: any, contentType?: string, progress?: any): any {
    return this.http(url, method, true, params, body, contentType, progress)
  }

  duration(from: any, to: any): string {
    let fromDate: Date = from ? new Date(from) : new Date();
    let toDate: Date = to ? new Date(to) : new Date();

    const diff: number = fromDate.getTime() - toDate.getTime();

    let result = '';
    if (diff > 0) {
      let d = Math.trunc(diff / 1000);
      let v = d % 60;
      result = (v < 10 ? '0' + v : '' + v) + ' ' + this.i18n(this.labels.duration.sec);

      d = Math.trunc(d / 60);
      v = d % 60;
      result = (v < 10 ? '0' + v : '' + v) + ' ' + this.i18n(this.labels.duration.min) + ' ' + result;

      d = Math.trunc(d / 60);
      v = d % 24;
      result = (v < 10 ? '0' + v : '' + v) + ' ' + this.i18n(this.labels.duration.hour) + ' ' + result;

      v = Math.trunc(d / 24);
      if (v !== 0) {
        result = (v < 10 ? '0' + v : '' + v) + ' ' + this.i18n(this.labels.duration.day) + ' ' + result;
      }
    }

    return result;
  }

  format(value: any, options?: DateFormatOptions | NumberFormatOptions | string | number): string {
    if (options) {
      const opt: any = options;
      if (opt['ds']) {
        return this._dateFormat.formatWithOptions(value, opt)
      } else {
        return this._decimalFormat.formatWithOptions(value, opt)
      }
    } else {
      if (Object.prototype.toString.call(value) === '[object Date]') {
        return this._dateFormat.format(value, 'DATE-TIME')
      } else {
        return this._decimalFormat.format(value, 2, false)
      }
    }
  }
}

export function clone(obj: any): any {
  let copy: any;

  // Handle scalar types
  if (obj === null || typeof obj !== 'object' || obj instanceof File) {
    return obj;
  }

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = [];
    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = clone(obj[i]);
    }
    return copy;
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {};
    for (let attr in obj) {
      if (obj.hasOwnProperty(attr)) {
        copy[attr] = clone(obj[attr]);
      }
    }
    return copy;
  }

  return obj;
}
