import { ILanguage } from '@/plugins/phoenix/types';

const lang: ILanguage = {
  code: 'lv',
  name: 'Latviešu',
  short: 'Lv',
  translates: {
    system: {
      logon: 'Pieslēgt',
      logout: 'Izeja',
      register: 'Reģistrācija',
      firstname: 'Vārds',
      lastname: 'Uzvārds',
      cor: 'Izvēlieties savas dzīvesvietas valsti',
      mailingAccept: 'Es vēlētos saņemt īpašos piedāvājumus',
      termsAcceptHead: 'Please read the Legal &amp; Privacy before proceeding with the registration in <b>%1</b> and purchase of cryptocurrency. You need to agree with them to continue.',
      termsAccept: 'I agree with <a href="%1" target="blank"><b>Legal &amp; Privacy</b></a>',
      emailCollect: 'Before proceeding with the registration in <b>%1</b> and purchase of cryptocurrency you need enter your email address to which we will send a verification code.',
      emailCheck: 'Pārbaudiet savu e-pastu un ievadiet verifikācijas kodu.',
      loginNeeded: 'You have already been registered in <b>%1</b> with this email. Enter a password to confirm access. If you don\'t remember your password, go to the login page at <a href="%2" target="blank">%1</a> and click "Forgot Password".',
      alreadyCommited: 'Jūsu darījums jau ir pabeigts. Lūdzu, aizveriet šo lapu.',
      login: 'Pieslēgt',
      loginName: 'Lietotājvārds',
      password: 'Parole',
      email: 'E-pasts',
      COR: 'Dzīvesvietas valsts',
      code: 'Kods',
      resendCode: 'Atkārtoti nosūtīt kodu',
      loading: 'Ielādē',
      siteCopyrights: 'All rights reserved',
      siteOperated: 'The website is operated by',
      siteLegalDocs: 'Legal &amp; Privacy',
      close: 'Aizvērt',
      back: 'Atpakaļ',
      continue: 'Turpināt',
      send: 'Sūtīt',
      buyAndSend: 'Pirkt un sūtīt',
      makeDeposit: 'Es pārskaitīju naudu',
      canClose: 'Jūs varat aizvērt šo logu',
      sendAgree: 'Es vēlos nosūtīt iegādāto kriptovalūtu uz iepriekš aizpildīto maku:',
      sendWarningHead: 'Uzmanieties no krāpniekiem un kibernoziedzniekiem!',
      sendWarningText: 'Vienmēr esiet piesardzīgs, izņemot kriptovalūtas. Kad naudas izņemšana ir nosūtīta, darījumu nav iespējams atcelt vai atsaukt.',
      depositWarningHead: 'Esiet uzmanīgi!',
      depositWarningTextFlex: 'You need to send coins to the <b>%1</b> address below. Amounts less than <b>%2 %3</b> will not be credited! Once a coins has been sent, it is impossible to cancel or reverse the transaction.',
      depositWarningTextStrict: 'You need to send coins to the address below <b>%1</b>. Amount not equal to <b>%2 %3</b> will not be credited! Once a coins has been sent, it is impossible to cancel or reverse the transaction.',
      selectCOR: 'Lūdzu, izvēlieties savu dzīvesvietas valsti zemāk esošajā sarakstā',
      cancel: 'Atcelt',
      wallet: 'Saņēmēja maka numurs',
      walletSender: 'Jusu maka numurs',
      forgetPassword: 'Aizmirsu paroli',
      forgetPasswordText: 'Ja nevarat atcerēties savu paroli, lūdzu, ievadiet savu platformas profila e-pasta adresi zemāk esošajā lodziņā, un mēs nosūtīsim instrukcijas paroles atkopšanai.',
      recoveryEmailSent: 'Pasts ar atkopšanas paroles instrukcijām ir nosūtīts',
      passwordHint: 'Parolei jābūt ar 8 vai vairāk rakstzīmēm ar jauktiem lielajiem burtiem, cipariem un pieturzīmēm',
      paymentDescriptor: 'Payment descriptor',
      paymentAmount: 'Payment amount',
      chargeAmount: 'Will be charged',
      phone: 'Tālrunis',
      address: {
        city: 'Pilsēta',
        state: 'Štats',
        country: 'Valsts',
        zip: 'Indekss',
        street: 'Iela',
      },
      birthday: 'Dzimšanas datums',
      document: {
        type: 'Dokumenta veids',
        types: {
          ID: 'ID karte',
          PS: 'Pase',
        },
        number: 'Dokumenta numurs',
        issued: 'Dokuments izdots',
        expired: 'Dokuments derīgs līdz',
      },
      bank: {
        account: 'Konta numurs',
        bic: 'Bankas kods (BIC)',
        branch: 'Bankas filiāle',
        owner: 'Konta īpašnieka vārds',
      },

      errors: {
        'HTTP': '%1 server is not available. Contact your administrator or try again.',
        'SYS': 'Your %1 has encountered a critical error. Current operation will be terminated.',
        'USR008': 'Verifikācijas kods nav derīgs.',
        'LGN': 'Nepareizs lietotājvārds vai parole.',
        'CARD_NUMBER': 'Nepareizs vai tukšs kartes numurs.',
        'CARD_EXPIRED': 'Incorrect or empty card expiration date.',
        'CARD_CVC': 'Nepareizs vai tukšs kartes derīguma termiņš.',
        'CARD_NAME': 'Tukšas kartes īpašnieka vārds.',
        'CARD_EMPTY': 'Please fill card data.',
        'CHK001': 'Nederīgs pieprasījuma solis',
        'CHK002': 'Pieprasījums nav atrasts',
        'CHK003': 'Nezināms pakalpojumu sniedzējs',
        'CHK004': 'Pieprasījums neizdevās',
        'CHK005': 'Pieprasījums atcelts',
        'ACC002': 'Insufficient funds on the card',
        'CHL001': 'Temporary transaction processing error, please try again later',
        'CHL002': 'Technical error, please contact support',
        'CHL003': 'Transaction processing error',
        'CHL004': 'Transaction processing error',
        'CHL005': 'Transaction processing error',
        'CHL006': 'Unprocessable card, please try another one',
        'CHL007': 'Temporary transaction processing error, please try again later',
        'CHL008': 'Unprocessable card, please try another one',
        'CHL009': 'Unprocessable card, please try another one',
        'FIN012': 'Transaction processing error',
        'PRC004': 'Transaction expired',
        'PRC005': 'Transaction processing error',
        'PRC014': 'Transaction has been canceled',
        'PRC019': 'Unprocessable card, please try another one',
        'PRC020': 'Incorrect card details (number, expiration date or name)',
        'PRCRT2': 'Transaction processing error',
        'PRCRT8': 'Unprocessable card, please try another one',
        'SYS007': 'Card security check failed (CVV/CVC or 3DS)',
        'SYS013': 'Transaction timeout',
      },
    },
    checkout: {
      initializing: 'Inicializēšana...',
      steps: {
        TERMS: {
          processing: 'Uzglabāšana piekrītu ... ',
        },
        COR: {
          processing: 'Valsts noteikšana... ',
        },
        BILL: {
          processing: 'Notiek apstrāde... ',
        },
        LOGON: {
          processing: 'Pieslēgšāna... ',
        },
        EMAIL: {
          processing: 'Pārbauda e-pastu... ',
        },
        NEWCS: {
          processing: 'Jūsu profila izveide... ',
        },
        IDENT: {
          processing: 'Identifikācijas izveide... ',
        },
        PAYMET: {
          processing: 'Kartes sagatavošana... ',
        },
        FINOPER: {
          processing: 'Notiek maksājuma apstrāde, lūdzu, uzgaidiet un neaizveriet šo logu... ',
        },
        WALLET: {
          processing: 'Meklē maku... ',
        },
        WAIT: {
          processing: 'Apstrāde... ',
          deposit: 'Gaida depozītu... ',
        },
      },
    },
  },
}

export default lang;
