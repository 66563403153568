import Vue from 'vue'
import { IPhoenixApp, IPhoenixModule, PhoenixApp, PhoenixOptions } from './library'

import en from '@/i18n/en'
import ru from '@/i18n/ru'
import lv from '@/i18n/lv'
import { ILanguage } from './types'
import settings from '../settings'

const languages: Array<ILanguage> = []
if (settings.company.companyUISettings.languages.includes('en')) languages.push(en)
if (settings.company.companyUISettings.languages.includes('ru')) languages.push(ru)
if (settings.company.companyUISettings.languages.includes('lv')) languages.push(lv)

const options: PhoenixOptions = {
  environment: {
    name: process.env.VUE_APP_ENV,
    debug: process.env.VUE_APP_ENV !== 'PROD',
  },

  modules: [],

  localization: {
    currentLanguage: 'en',
    languages,
  },
}

const $ph: IPhoenixApp = new PhoenixApp()

Vue.component('v-node', {
  props: {
    value: { type: [Object, Array], required: true },
    tag: { type: String, default: 'div' },
    options: { type: Object, default: () => { } },
  },
  render: function (h) {
    let nodes = this.value
    if (!Array.isArray(nodes)) {
      nodes = [nodes]
    }
    return h(this.tag, this.options, nodes)
  },
})

export function PhoenixStart(modules: Array<IPhoenixModule>, debug: boolean, router: any) {
  options.modules = modules;
  options.environment!.debug = debug

  Vue.use($ph, options)

  if (router) {
    $ph.setup({ router });
    router.beforeEach((to, from, next) => {
      $ph.route(to, from, next)
    })
  }
}

export default $ph
