export type DatePart = 'DAY' | 'MONTH' | 'YEAR';

declare global {
  interface Number {
    whole(group: number, groupDelimiter: string, absolute: boolean): number;
    frac(decimal: number): string;
    round(decimal: number): number;
    normalize(): string;
  }

  interface Date {
    isToday(): boolean;
    isExpired(to: Date | null): boolean;
    native(withTime: boolean): string;
    add(n: number, part?: DatePart): Date;
    setTimeOnly(date: Date): Date;
    nowUTC(): Date;
    fromUTC(): Date;
  }
}

/* eslint no-extend-native: ["error", { "exceptions": ["Number", "Date"] }] */
Number.prototype.whole = function (): number {
  return Math.floor(Number(this));
}

Number.prototype.frac = function (decimal: number): string {
  let result = '' + Math.abs((Number(this) % 1) * Math.pow(10, decimal))
  while (result.length < decimal) {
    result = '0' + result;
  }
  return result;
}

Number.prototype.round = function (decimal: number): number {
  const prec = Math.pow(10, decimal)
  return Math.round((this.valueOf() + Number.EPSILON) * prec) / prec
}

Number.prototype.normalize = function (): string {
  let numbers: Array<string>;
  let result: string;

  const s = this.toString();

  if (s.includes('e-')) {
    numbers = s.split('e-');
    result = '0'.repeat(parseFloat(numbers[1])) + numbers[0];
    return result.substr(0, 1) + '.' + result.substr(1, result.length);
  }

  if (s.includes('e+')) {
    numbers = s.split('e+');
    result = `${numbers[0]}${'0'.repeat(parseFloat(numbers[1]))}`;
    return result;
  }

  return s;
}

Date.prototype.native = function (withTime: boolean): string {
  let s = this.getFullYear() +
    '-' +
    (this.getMonth() < 9 ? '0' + (this.getMonth() + 1) : (this.getMonth() + 1)) +
    '-' +
    (this.getDate() < 10 ? '0' + this.getDate() : this.getDate());

  if (withTime) {
    s = s + 'T' + (this.getHours() < 10 ? '0' + this.getHours() : this.getHours()) +
      ':' +
      (this.getMinutes() < 10 ? '0' + this.getMinutes() : this.getMinutes()) +
      ':' +
      (this.getSeconds() < 10 ? '0' + this.getSeconds() : this.getSeconds())
  }

  return s;
}

Date.prototype.isToday = function (): boolean {
  let today = new Date()
  return this.getFullYear() === today.getFullYear() && this.getMonth() === today.getMonth() && this.getDate() === today.getDate()
}

Date.prototype.isExpired = function (to: Date | null = null): boolean {
  let toDate = to || new Date();
  return this.getTime() - toDate.getTime() < 0;
}

Date.prototype.add = function (n: number, part: DatePart = 'DAY'): Date {
  if (part === 'DAY') {
    this.setDate(this.getDate() + n);
  } else if (part === 'MONTH') {
    this.setMonth(this.getMonth() + n);
  } else {
    this.setFullYear(this.getFullYear() + n);
  }
  return this;
}

Date.prototype.setTimeOnly = function (date: Date): Date {
  const h = date.getHours();
  const m = date.getMinutes();
  const s = date.getSeconds();
  const result = new Date(this.getTime());
  result.setHours(h, m, s, 0);
  return result;
}

Date.prototype.nowUTC = function (): Date {
  const dt = new Date();
  return new Date(Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate(), dt.getHours(), dt.getMinutes(), dt.getSeconds()));
}

Date.prototype.fromUTC = function (): Date {
  return new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate(), this.getHours(), this.getMinutes(), this.getSeconds()));
}

export interface NumberFormatOptions {
  dec: number
  trunc?: boolean
}

export class NumberFormat {
  public decimalChar: string
  public group: number
  public groupDelimiter: string

  constructor(
    decimalChar: string = '.',
    group: number = 3,
    groupDelimiter: string = ' ',
  ) {
    this.decimalChar = decimalChar
    this.group = group
    this.groupDelimiter = groupDelimiter
  }

  public formatWithOptions(value: number, options: NumberFormatOptions = { dec: 2, trunc: false }): string {
    return this.format(value, options.dec, options.trunc === true);
  }

  public format(value: number, decimal: number = 2, truncate: boolean = false): string {
    let re = '\\d(?=(\\d{' + (this.group || 3) + '})+' + (decimal > 0 ? '\\D' : '$') + ')'
    let num = this.correctNotation(value.toFixed(Math.max(0, ~~decimal)));

    const result = (this.decimalChar ? num.replace('.', this.decimalChar) : num).replace(new RegExp(re, 'g'), '$&' + (this.groupDelimiter || ','));
    return truncate ? this.truncate(result) : result;
  }

  private truncate(value: string): string {
    if (value.indexOf(this.decimalChar) > 0) {
      let result = '';
      let trunc = true;
      for (let i = value.length - 1; i >= 0; i--) {
        if (trunc && value.charAt(i) === this.decimalChar) {
          trunc = false;
          continue;
        }

        if (trunc && value.charAt(i) !== '0') {
          trunc = false;
        }

        if (!trunc) {
          result = value.charAt(i) + result;
        }
      }

      return result;
    } else {
      return value;
    }
  }

  private correctNotation(x: any) {
    if (Math.abs(x) < 1.0) {
      let e = parseInt(x.toString().split('e-')[1]);
      if (e) {
        x *= Math.pow(10, e - 1);
        x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
      }
    } else {
      let e = parseInt(x.toString().split('+')[1]);
      if (e > 20) {
        e -= 20;
        x /= Math.pow(10, e);
        x += (new Array(e + 1)).join('0');
      }
    }
    return x;
  }
}

export type DateMask = 'YYYY-MM-DD' | 'DD-MM-YYYY' | 'MM-DD-YYYY';
export type TimeMask = 'HH:MM' | 'HH:MM:SS' | '';
export type DateFormatStyle = 'DATE' | 'TIME' | 'DATE-TIME' | 'TODAY-TIME';

export interface DateFormatOptions {
  ds: DateFormatStyle
}

export class DateFormat {
  public delimiter: string
  public dateMask: DateMask
  public timeMask: TimeMask

  constructor(
    delimiter: string,
    dateMask: DateMask = 'MM-DD-YYYY',
    timeMask: TimeMask = 'HH:MM',
  ) {
    this.delimiter = delimiter
    this.dateMask = dateMask
    this.timeMask = timeMask
  }

  public formatWithOptions(value: Date, options: DateFormatOptions = { ds: 'DATE' }): string {
    return this.format(value, options.ds);
  }

  public format(value: Date, style: DateFormatStyle = 'DATE'): string {
    if (!value) {
      return '';
    }

    value = new Date(value)

    if (style === 'DATE') {
      return this.formatDate(value);
    } else if (style === 'TIME') {
      return this.formatTime(value);
    } else if (style === 'DATE-TIME') {
      return this.formatDate(value) + ' ' + this.formatTime(value);
    } else if (style === 'TODAY-TIME') {
      return value.isToday() ? this.formatTime(value) : this.formatDate(value) + ' ' + this.formatTime(value);
    } else {
      return '';
    }
  }

  private formatDate(value: Date): string {
    if (this.dateMask === 'YYYY-MM-DD') {
      return value.getFullYear() + this.delimiter + this.expand(value.getMonth() + 1) + this.delimiter + this.expand(value.getDate());
    } else if (this.dateMask === 'DD-MM-YYYY') {
      return this.expand(value.getDate()) + this.delimiter + this.expand(value.getMonth() + 1) + this.delimiter + value.getFullYear();
    } else if (this.dateMask === 'MM-DD-YYYY') {
      return this.expand(value.getMonth() + 1) + this.delimiter + this.expand(value.getDate()) + this.delimiter + value.getFullYear();
    } else {
      return '';
    }
  }

  private formatTime(value: Date): string {
    if (this.timeMask === 'HH:MM') {
      return this.expand(value.getHours()) + ':' + this.expand(value.getMinutes());
    } else if (this.timeMask === 'HH:MM:SS') {
      return this.expand(value.getHours()) + ':' + this.expand(value.getMinutes()) + ':' + this.expand(value.getSeconds());
    } else {
      return '';
    }
  }

  private expand(n: number): string {
    return n < 10 ? '0' + n : '' + n;
  }
}
